import { TLoanSidebar } from "src/types/ui";

import { LoanSidebar } from "./ui";

export const FieldTaskType = {
    FILL: 'fill',
    SIGN: 'sign',
    ASSIGN_SIGNEE: 'assign_signee',
} as const;

export const PDFTRON_CUSTOM_DATA_SIGNEE_ID = 'pdftronCustomDataSigneeId';
export const PDFTRON_CUSTOM_DATA_SIGNEE_NAME = 'pdftronCustomDataSigneeFullName';

export const QUERY_PARAM_ASSIGN_SIGNEE = 'assignSignee';


export const TaskViewTaskTypeMap = {
    ['MESSAGE']: "Messages",
    ['UPLOAD']: "AnswerQuestionTask",
    ['FILL_SIGN']: "FillFormTask",
}

export const TaskGroupLabel: Record<TLoanSidebar, string> = {
    "AnswerQuestionTask": "Upload",
    "AssignSigneeDocumentTask": "Assign signee",
    "FillFormTask": "Fill and Sign",
    "ApproveAnswerTask": "Accept",
    "ShoeBoxTask": "",
    "FillDocumentTask": "Fill and Sign",
    "FillDocumentTaskDto": "Fill and Sign",
    "SignTask": "Fill and Sign",
    "AssignQuestionTask": "Assign",
};

export const TaskGroupIdLoanSidebarMap = {
    ['fillAndSign']: LoanSidebar.TASKS_LIST_FILL_FORM,
    ['upload']: LoanSidebar.TASKS_LIST_UPLOAD,
    ['Accept']: LoanSidebar.TASKS_LIST_APPROVE,
    ['Assign']: LoanSidebar.TASKS_LIST_ASSIGN,
}